import React, { useState } from 'react'
import "./home.css"

const Home = () => {
    
    const [showForm,setShowForm] = useState(false)
    const handleShowForm = () => {
        setShowForm(!showForm)
    }

  return (
    <div className='Home'>
        <div className="header">
            <span>S SHANKARAPPA & ASSOCIATES</span>
        </div>

        <div className="banner">
            <img src="https://img1.wsimg.com/isteam/stock/4016/:/fx-gs" alt="" />
        </div>

        <div className="legalExpert">
            <h1>Legal Expertise You Can Rely On</h1>
            <button>
                <a href="tel:+91 9886493060">
                    <div className='btnStyle'></div>
                        <span>For Consultation</span>
                    <div className='btnStyle'></div>
                </a>
            </button>
        </div>

        <div className="footer">
            <p>COPYRIGHT © 2022 S SHANKARAPPA & ASSOCIATES - ALL RIGHTS RESERVED.</p>
            <p className="copyrightPara">POWERED BY TUDO TECHNOLOGIES</p>
        </div>


        <div className="messageDiv" onClick={handleShowForm}>
            <a href="https://wa.me/+919886493060" target="_blank">

            <svg viewBox="0 0 24 24" fill="currentColor" width="44" height="44" data-ux="Icon" class="x-el x-el-svg c2-1 c2-2 c2-q c2-r c2-s c2-3 c2-4 c2-5 c2-6 c2-7 c2-8"><g fill="currentColor"><rect x="4" y="6" width="16" height="10.222" rx="1.129"></rect><path d="M8.977 18.578l.2-2.722a.564.564 0 01.564-.523h3.61c.548 0 .774.705.327 1.024l-3.81 2.721a.564.564 0 01-.89-.5z"></path></g></svg>

            {/* <svg viewBox="0 0 24 24" fill="currentColor" width="34" height="34" data-ux="Icon" class="x-el x-el-svg c2-1 c2-2 c2-2e c2-r c2-s c2-3 c2-4 c2-5 c2-6 c2-7 c2-8"><path fill-rule="evenodd" d="M19.219 5.22a.75.75 0 0 0-1.061 0l-5.939 5.939-5.939-5.94a.75.75 0 1 0-1.061 1.062l5.939 5.939-5.939 5.939a.752.752 0 0 0 0 1.06.752.752 0 0 0 1.061 0l5.939-5.938 5.939 5.939a.75.75 0 1 0 1.061-1.061l-5.939-5.94 5.939-5.938a.75.75 0 0 0 0-1.061"></path></svg> */}
            </a>
            {/* {
                showForm == true && 
                <div className="messageForm">
                    <h4 className="messageHeader">
                        S Shankarappa & Associates
                    </h4>

                    <p className='letUsKnowMsg'>
                    Hi! Let us know how we can help and we’ll respond shortly.
                    </p>
                </div>
            } */}
        </div>

    </div>
  )
}

export default Home