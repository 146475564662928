import './App.css';
import Home from './Components/Home/Home';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC-_CEUhGHK7N4vOTCNtAbbw-DMUqQnWm0",
  authDomain: "shankarappa-b6620.firebaseapp.com",
  projectId: "shankarappa-b6620",
  storageBucket: "shankarappa-b6620.appspot.com",
  messagingSenderId: "576915769757",
  appId: "1:576915769757:web:7bd03ab44b88125a09c774",
  measurementId: "G-56S15B9FVX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
   <>
    <Home/>
   </>
  );
}

export default App;
